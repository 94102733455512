/* Mixins */
.view-line-arrow {
  height: 100%;
  width: 100%;
  position: relative;
}
.view-line-arrow .passby {
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: white;
  border: 1px solid rgb(34, 14, 223);
  cursor: move;
}