@charset "UTF-8";
/* Mixins */
.view-parking {
  overflow: hidden;
  height: 100%;
  padding: 0 1px;
  position: relative;
}
.view-parking .parking-car {
  width: 100%;
  margin-top: 8px;
}
.view-parking .parking-div {
  height: 100%;
  width: 100%;
}
.view-parking .close-parking {
  background-color: #999;
}
.view-parking .bad-parking {
  background-color: firebrick;
}
.view-parking .free-parking {
  background-color: green;
}
.view-parking .parking-no {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
}
.parking-tip-title {
  font-size: 20px;
  font-weight: bold;
  padding: 0 16px;
}
.parking-tip-status {
  font-size: 16px;
  font-weight: bold;
}
.parking-tip-status-空闲 {
  color: green;
}
.parking-tip-status-故障, .parking-tip-status-占用 {
  color: firebrick;
}
.parking-tip-status-未启用 {
  color: #999;
}