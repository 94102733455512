/* Mixins */
.view-chart-panel {
  background: #100c2a !important;
}
.view-chart-panel .chart-panel-header {
  margin-bottom: 4px;
}
.view-chart-panel .chart-panel-header .chart-panel-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-left: 8px;
}
.view-chart-panel .chart-panel-header .chart-panel-title .chart-panel-title-triangle {
  font-size: 16px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  color: green;
}
.view-chart-panel .chart-panel-header .chart-panel-desc {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 8px;
}
.view-chart-panel .chart-panel-header .chart-panel-bottom {
  position: relative;
  height: 5px;
}
.view-chart-panel .chart-panel-header .chart-panel-bottom .chart-panel-line {
  height: 1px;
  position: absolute;
  top: 2px;
  left: 13px;
  right: 13px;
  background-color: rgba(255, 255, 255, 0.6);
}
.view-chart-panel .chart-panel-header .chart-panel-bottom .chart-panel-dot {
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #fff;
}
.view-chart-panel .chart-panel-header .chart-panel-bottom .chart-panel-dot-left {
  left: 4px;
}
.view-chart-panel .chart-panel-header .chart-panel-bottom .chart-panel-dot-right {
  right: 4px;
}
.view-chart-panel .chart-panel-content {
  position: relative;
  height: calc(100% - 39px);
}