/* Mixins */
.topo-dropdown-overlay .ant-dropdown-menu {
  background-color: #112138;
  padding: 0;
}
.topo-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item {
  font-size: 12px;
  padding: 0.5px 8px;
  color: #fff;
}
.topo-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: transparent;
}