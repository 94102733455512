/* Mixins */
.view-parking-text {
  text-align: left;
  color: #fff;
}
.view-parking-text .parking-title {
  padding-right: 8px;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: bold;
}
.view-parking-text .parking-desc {
  font-size: 12px;
  -webkit-transform: scale(0.83);
          transform: scale(0.83);
}
.view-parking-text .parking-count {
  -webkit-transform: scale(0.83);
          transform: scale(0.83);
}